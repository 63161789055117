.button-comp {
  --btn-color: var(--color-button, var(--color-secondary, var(--color-primary)));
  --btn-color-on: var(--color-on-button, var(--color-on-secondary, var(--color-on-primary)));
  --loading-spinner-color: currentcolor;

  &.danger {
    --btn-color: var(--color-error);
    --btn-color-on: var(--color-on-error);
  }

  background-color: var(--btn-color);
  color: var(--btn-color-on);
  border-radius: var(--corner-radius);
  padding: var(--pad-sm) var(--pad);
  transition: box-shadow 0.25s;
  border: none;
  cursor: pointer;
  user-select: none;
  font-family: var(--font-title);
  font-size: inherit;
  outline: none;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  position: relative;
  flex-shrink: 0;

  #center-container {
    margin: auto;
    display: flex;
    align-items: center;
  }

  #active-marker {
    display: none;
    position: absolute;
    bottom: 0px;
    left: var(--pad-sm);
    right: var(--pad-sm);
    height: 4pt;
    border-radius: var(--corner-radius);
    background-color: var(--color-accent);
  }

  &.floating:not(.outline) {
    box-shadow: var(--shadow-1);

    &:focus, &:hover {
      box-shadow: var(--shadow-1);
    }

    &:active {
      box-shadow: var(--shadow-2);
    }
  }

  @media (min-width: 961px) { min-height: 34pt; }
}

.button-comp.flat, .button-comp.round {
  padding: 0pt;
  min-width: 34pt;
  background-color: inherit;
  color: inherit;
  box-shadow: none;
}

.button-comp.outline {
  background-color: inherit;
  color: var(--btn-color);
  box-shadow: none;
  border: solid 1px var(--btn-color);
}

.button-comp.flat {
  &.danger, &.highlight {
    color: var(--btn-color);
  }
}

.button-comp.round {
  #center-container {
    background-color: var(--btn-color);
    color: var(--btn-color-on);
    border-radius: 50%;
    width: 28pt;
    height: 28pt;

    i { margin: auto; }
  }
}

.button-comp.active {
  /* border-radius: 0px; */
  /* color: var(--color-accent); */
  /* background-color: var(--color-surface); */

  /* color: var(--color-accent-light); */
  /* text-shadow: 0px 0px 3px #00000099; */

  #active-marker {
    display: inherit;
  }

  /* .icon-container { */
  /*   color: var(--color-accent); */
  /*   background-color: #ffffff; */
  /*   border-radius: 50%; */
  /* } */
}
