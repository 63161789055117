.tab-select-comp {
  padding: 2px;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid var(--color-neutral-light);
  border-radius: 50px;

  .tab-container {
    padding: var(--pad-xs) var(--pad);

    p {
      font-size: var(--font-size-small);
      color: var(--color-neutral-dark);
    }

    &.selected {
      border-radius: 30px;
      background: var(--color-primary-light);

      p { color: white; }
    }
  }
}
