#modal-overlay-comp {
  z-index: 101;
  background-color: #00000099;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;

  #modal-container {
    margin: auto;
    background-color: var(--color-surface);
    border-radius: 8pt;
    box-shadow: 4pt 4pt 1pt 0pt rgba(0, 0, 0, 0.25);
    color: var(--color-on-surface);
    padding: var(--pad-lg);
    overflow: scroll;
    max-height: calc(100% - var(--pad-lg) * 4);
    max-width: calc(100% - var(--pad-lg) * 4);
  }

  @media (min-width: 481px) {
    #modal-container { min-width: 260pt; }
  }
  @media (max-width: 480px) {
    #modal-container {
      width: calc(100% - var(--pad-lg) * 4);
    }
  }
}
