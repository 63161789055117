/* These are decent rule-of-thumb media queries for different
 * screen resolutions.
 * We should assume we're on the smallest screen, and then use
 * the 3rd for landscape ipads, and the 4th for PC browsers.
 */
@media (min-width: 320px) { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width: 481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width: 1281px) { /* hi-res laptops and desktops */ }

* {
  margin: 0pt;
  padding: 0pt;
  scrollbar-width: none; /* hide scrollbar for Firefox and some others */
  -ms-overflow-style: none; /* hide scrollbar for IE and Edge */
}

/* hide scrollbar for Chrome/Opera/Safari */
::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --font-title: "Montserrat", "Georgia", "Times", "Baskerville", serif;
  --font-title-weight: 400;
  --font-light: "Montserrat Light", "Gotham-Light", "Verdana", sans-serif;
  --font-regular: "Open Sans", "Avenir", "Arial", sans-serif;
  --font-field: var(--font-regular); /* Courier or monospace also look good here */
  --font-size-large: 16pt;
  --font-size-medium: 14pt;
  --font-size-small: 12pt;
  --font-size-xs: 10pt;

  /* This is the common padding and margins used throughout the app */
  --pad: 12pt;
  --pad-xs: 4pt;
  --pad-sm: 8pt;
  --pad-lg: 18pt;
  --pad-xl: 36pt;

  /* Alternate padding */

  /*
  --pad: 16px;
  --pad-xs: 4px;
  --pad-sm: 8px;
  --pad-lg: 24px;
 */

  /* This is the common corner radius, used by cards, inputs, etc. */
  --corner-radius: 4pt;

  /* These are standard material shadows based on depth */
  --shadow-1: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  --shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  --shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  --shadow-4: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  --shadow-5: 0 19px 38px rgba(0,0,0,0.3), 0 15px 12px rgba(0,0,0,0.22);
  --shadow-bottom: 0px 1px 5px #cccccc, 0px 1px 3px #999999;
  --shadow-fine: 0px 1px 1px #dadada;

  --mti-red: #c10824;
  --mti-navy: #1b3760;
  --mti-bright-blue: #116db6;
  --mti-hue1: #a6a6a6;
  --mti-hue2: #d9d9d9;
  --mti-hue3: #e3e2e1;
  --mti-hue4: #ededed;
  --mti-hue5: #ffffff;
  --mti-green: #24c108;
  --mti-yellow: #ffc108;

  --color-primary: var(--mti-bright-blue);
  /* The dark and light accents are recommended by google tools, based on our primary */
  --color-primary-dark: var(--mti-navy); /* #004386; */
  --color-primary-light: #5a9be9;
  --color-on-primary: white;
  --color-on-primary-dark: white;
  --color-on-primary-light: white;

  --color-neutral-dark: var(--mti-hue1);
  --color-neutral: var(--mti-hue2);
  --color-neutral-light: var(--mti-hue4); /* #f6f6f6; /* var(--mti-hue4); */

  /* The secondary color is often used for accents. I want to use these throughout the app, but for
     the basic mti theme, just keep them the same as the primary color. We could toy with using the
     Red, or maybe have the navy color be the primary, with the bright blue being the secondary in
     the future. */

  --color-secondary: var(--color-primary);
  --color-secondary-dark: var(--color-primary-dark);
  --color-secondary-light: var(--color-primary-light);
  --color-on-secondary: var(--color-on-primary);
  --color-on-secondary-dark: var(--color-on-primary-dark);
  --color-on-secondary-light: var(--color-on-primary-light);

  --color-accent: var(--mti-red); /* #ff9999; /* #c10824; var(--mti-red); /* #ff9933; */
  --color-accent-light: #ffbbcc; /* #c10824; var(--mti-red); /* #ff9933; */

  --color-error: var(--mti-red);
  --color-on-error: white;

  --color-success: green;
  --color-on-success: white;

  --color-surface: white;
  --color-on-surface: #222222;
  --color-background: #efefef;
  --color-on-background: #111111;

  --color-button: var(--color-primary-light);
  --color-on-button: var(--color-on-primary-light);

  background-color: var(--color-background);
  color: var(--color-on-background);
  font-size: var(--font-size-medium);
  font-family: var(--font-regular);

  --bar-height: 40pt;
  --action-icon-size: 24pt;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  cursor: pointer;
  font-family: var(--font-light);
}

h1, h2, h3, h4 {
  margin: 0pt;
  font-family: var(--font-title);
  font-weight: var(--font-title-weight, 600);
}

h1 { font-size: 24pt; }

select {
  font-family: var(--font-regular);
  font-size: var(--font-size-small);
  padding: var(--pad-sm);
  border: 1px solid var(--color-neutral-light);
  border-radius: var(--corner-radius);
}

.page {
  background: var(--color-surface);
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow: scroll;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: scroll;
  flex: 1;

  & > .card {
    margin: 2px 4px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > * {
      padding: var(--pad-sm) var(--pad);
    }
  }
}

.cell {
  cursor: pointer;
  background-color: var(--color-surface);
  color: var(--color-on-surface);
  padding: var(--pad) var(--pad);
  border-bottom: 1pt solid #cccccc;
}

.subtitle {
  font-size: small;
  opacity: 60%;
}

.row {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.button-row {
  gap: var(--pad-xs);

  button {
    flex-grow: 1;
    flex-basis: 0;
  }
}

i.material-icons.state-icon-comp {
  &.grey { color: var(--color-neutral); }
  &.green { color: var(--mti-green); }
  &.yellow { color: var(--mti-yellow); }
  &.red { color: var(--mti-red); }
}

.cursor { cursor: pointer; }
