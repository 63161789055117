.ble-provision-comp {
  align-items: center;
  padding-top: var(--pad-lg);
  width: 100%;
  flex-grow: 1;
  @media (min-height: 800px) { padding-top: var(--pad-xl); }

  h1 { font-size: var(--font-size-large); }

  img {
    width: 55vw;
    @media (min-height: 800px) { width: 65vw; }
  }

  .content-container {
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    flex-grow: 1;

    .main-content {
      gap: var(--pad-xs);
      @media (min-height: 800px) { gap: var(--pad); }

      .info { font-size: var(--font-size-small); }
      .connection-status { margin: var(--pad-lg) 0; }
    }
  }

  .button-row { width: 100%; }
}
