.hierarchy-list-comp {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .cell:not(.position-cell-comp) {
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    border-bottom: none;
    padding: var(--pad-sm) var(--pad);

    &:not(.store-cell) { color: white; }
    & > :first-child { flex: 1; }
    .button-comp { padding: var(--pad-sm); }
  }

  .store-cell {
    background: #efefef;
    color: var(--color-on-neutral);
    gap: var(--pad-sm);
  }

  .floor-cell { background: #091633; }
  .area-cell { background: #003366; }
  .fixture-cell { background: #6699cc; }

  .position-cell-comp {
    background: white;
    color: black;
  }

  .move-btn { font-size: var(--font-size-small); }
  .selected { opacity: 100%; }
  .invalid { opacity: 20%; }

  .area-cell.cell {
    align-self: stretch;
    flex-direction: column;
    align-items: stretch;

    & > .row:first-child > :first-child { flex: 1; }
  }

  .fixture-cell.cell.furniture {
    background: #dfdfe3; /* #efefef; */
    color: black;
  }
}
