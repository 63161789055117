.secured-products-comp {
  h1 { margin: var(--pad); }
}

.secured-product-modal-comp {
  gap: var(--pad);

  h3 {
    color: var(--color-primary);
    text-decoration: underline;
  }

  .col {
    gap: var(--pad-xs);
  }
}
