/* This code was pulled from https://fonts.googleapis.com/icon?family=Material+Icons
 * with only minimal changes (relative urls and stylelint comments)
 * We did this, instead of using the cdn directly, so that we could pin the version,
 * and also because MAP will be an entirely offline application. */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), url("./materialIcons.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: "liga"; /* stylelint-disable-line property-no-vendor-prefix */
  -moz-osx-font-smoothing: grayscale;
}
