@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 0% 200%;
  src: local("Open Sans"), url("./OpenSans-VariableFont_wdth\,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 100 1000;
  font-stretch: 0% 200%;
  src: local("Open Sans"), url("./OpenSans-Italic-VariableFont_wdth\,wght.ttf") format("truetype");
}
