.zone-editor-comp {
  gap: var(--pad-sm);

  .modal-actions {
    gap: var(--pad-xs);

    button {
      flex-grow: 1;
      min-width: 50%;
    }
  }
}
