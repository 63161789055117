@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 1000;
  font-stretch: 0% 200%;
  src: local("Montserrat"), url("./Montserrat-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 1000;
  font-stretch: 0% 200%;
  src: local("Montserrat"), url("./Montserrat-Italic-VariableFont_wght.ttf") format("truetype");
}
