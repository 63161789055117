.ble-unlock-comp {
  align-items: center;
  padding-top: var(--pad);
  width: 100%;
  flex-grow: 1;
  @media (min-height: 800px) { padding-top: var(--pad-xl); }

  h1 { font-size: var(--font-size-large); }

  img { width: 80vw; }

  .content-container {
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    flex-grow: 1;

    .main-content {
      gap: var(--pad-xs);
      @media (min-height: 800px) { gap: var(--pad); }

      .connection-status { margin: var(--pad-lg) 0; }
    }
  }

  .button-row { width: 100%; }
}
