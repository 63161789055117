.log-window-comp {
  max-height: 30vh;
  font-size: 10pt;
  overflow: scroll;
  padding: var(--pad-xs);

  .logs-container {
    gap: var(--pad-xs);

    .clear-btn {
      position: fixed;
      right: 4pt;
    }
  }
}
