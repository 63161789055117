.ble-onboarding-comp {
  padding: var(--pad);
  gap: var(--pad-lg);

  h1 { text-align: center; }

  ul {
    gap: var(--pad);

    li {
      gap: var(--pad-lg);

      i {
        font-size: 40px;
        color: var(--color-primary-dark);
      }

      .li-main-text { font-size: var(--font-size-large); }
      .li-subtext { font-size: var(--font-size-small); }
    }
  }

  .action-container {
    gap: var(--pad-sm);
    justify-content: flex-end;
    flex-grow: 1;
  }
}
