.device-details-comp {
  gap: var(--pad-xs);

  .row {
    align-items: center;
    gap: var(--pad-xs);
  }

  .devices-container {
    gap: var(--pad-xs);

    .device-container {
      gap: var(--pad-xs);

      .serial {
        font-family: Courier, monospace;
        background: var(--color-neutral-light);
        border-radius: var(--corner-radius);
        border: 1pt solid var(--color-neutral);
        padding: 2pt var(--pad-sm);
        overflow: scroll;
      }
    }
  }

  .state-information {
    align-items: center;
    gap: var(--pad-xs);
  }

  .alarming { color: var(--mti-red); }
  .unhealthy, .disarmed { color: var(--mti-yellow); }
  .armed { color: var(--mti-green); }
  .default { color: var(--color-neutral-dark); }
}
