.user-edit-comp {
  gap: var(--pad-sm);

  form {
    gap: var(--pad);

    .key-user-container {
      .checkbox-container { gap: var(--pad-sm); }
      .info { margin: var(--pad-xs) 0; }
    }
  }

  .hidden { display: none; }
}

.location-select-comp {
  .selected-locations-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--pad-xs);
    margin-bottom: var(--pad-sm);
  }

  .selected-location {
    transform: translateY(1px);
    font-size: var(--font-size-small);
    background-color: var(--color-neutral-light);
    border-radius: var(--corner-radius);
    padding: var(--pad-sm);

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--pad-sm);
    }

    i {
      color: var(--color-neutral-dark);

      &:hover {
        cursor: pointer;
        color: var(--color-on-neutral);
      }
    }
  }

  .locations-list {
    max-height: 140px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    margin: 0 0 var(--pad) 0;
    border: 1px solid var(--color-neutral);
    border-radius: var(--corner-radius);

    .location-cell {
      font-size: var(--font-size-small);
      padding: var(--pad-sm);

      &:not(:first-child) {
        border-bottom: 1px solid var(--color-neutral-light);
      }

      &:hover {
        cursor: pointer;
        background-color: var(--color-neutral-light);
      }
    }
  }
}
