#hierarchy-comp {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  #canvas-container {
    height: 100%;
    flex: 1;
  }
}
