#sidebar-menu-comp {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.menu-item-comp {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: color 0.25s;
  cursor: pointer;
  background: inherit;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  color: inherit;

  &:hover {
    color: var(--color-primary-light);
  }

  #content {
    padding: var(--pad-sm) 0;
    display: flex;
    align-items: center;
    /* border-bottom: solid 1px var(--mti-hue2); */
    /* background-color: var(--color-surface); */
    /* transition: background-color 0.25s; */

    i {
      /* color: #666666; */
      margin: 8pt 16pt;
    }

    span { flex: 1; }
  }

  #divider {
    height: 1px;
    background-color: var(--color-neutral-light);
    margin: 0 var(--pad);
  }
}
