.store-edit-comp {
  .col, .row { gap: var(--pad-sm); }

  .section {
    margin: var(--pad) 0;

    .subheading {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: var(--font-size-xs);
    }
  }
}
