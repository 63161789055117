.searchable-select-comp {
  ul {
    font-size: var(--font-size-small);
    list-style: none;
    border: none;

    &.open {
      border: 1px solid var(--color-neutral-light);
      border-radius: var(--corner-radius);
      max-height: 200px;
      overflow: scroll;
    }

    li {
      padding: var(--pad-sm);
      cursor: pointer;
    }
  }
}
