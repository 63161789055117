.login-comp {
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--color-background);

  #brand-logo {
    margin-bottom: 32pt;
    display: flex;
    align-items: center;
    font-size: 48pt;
    font-family: var(--font-title);
    font-weight: 600;

    & img {
      height: 66pt;
      margin-right: var(--pad-sm);
    }
  }

  .card {
    background: var(--color-surface);
    color: var(--color-on-surface);
    border-radius: var(--corner-radius);
    display: inline-block;
    box-shadow: var(--shadow-1);
    padding: var(--pad-lg);
    margin-bottom: var(--pad);
  }

  .card-header {
    width: 100%;
    text-align: center;
  }

  .link {
    font-size: var(--font-size-small);
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--pad);
  }
}
