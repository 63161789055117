.user-details-comp {
  gap: var(--pad-sm);

  .actions {
    display: flex;
    flex-direction: column;
    gap: var(--pad-sm);
    align-items: stretch;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 { font-size: var(--font-size-medium); }
  }

  .resources-container {
    display: flex;
    flex-direction: column;
    gap: var(--pad-sm);

    .resource-row {
      display: flex;
      justify-content: space-between;
      gap: var(--pad);

      .role {
        font-size: 10pt;
        background-color: var(--color-neutral-light);
        border-radius: 3px;
        padding: 3px var(--pad-sm);
      }
    }
  }
}
