.device-cell-comp {
  cursor: pointer;
  overflow: scroll;
  font-size: var(--font-size-small);
  gap: var(--pad-xs);

  @media (min-width: 641px) {
    &.col { flex-direction: row-reverse; }
  }

  .row {
    align-items: center;
    justify-content: space-between;
    gap: var(--pad-xs);
  }

  .alarming { color: var(--mti-red); }
  .unhealthy, .disarmed { color: var(--mti-yellow); }
  .armed { color: var(--mti-green); }
  .default, .unassigned { color: var(--color-neutral-dark); }

  .devices-container {
    flex-grow: 1;
    gap: var(--pad-xs);

    .device-container {
      text-wrap: nowrap;

      @media (min-width: 641px) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: left;
        gap: var(--pad-sm);
      }

      .serial {
        font-family: Courier, monospace;
        background: var(--color-neutral-light);
        border-radius: var(--corner-radius);
        border: 1pt solid var(--color-neutral);
        padding: 2pt var(--pad-sm);
        overflow: scroll;
      }
    }
  }
}
