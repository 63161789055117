.store-details-comp {
  background-color: var(--color-surface);

  .content {
    flex: 1;
    overflow: scroll;
    margin: 0 var(--pad);

    h2 { margin-bottom: var(--pad); }

    h3 {
      border-bottom: 1px solid var(--color-primary);
      padding: var(--pad-sm) 0;
    }

    ul { list-style: none; }

    .row {
      justify-content: flex-start;
      align-items: flex-start;
      margin: var(--pad);
    }

    .col {
      justify-content: flex-start;
      min-width: 30%;
    }

    .subtitle { margin-top: var(--pad-sm); }

    .hub-info {
      align-items: center;
      gap: var(--pad-xs);
      margin: var(--pad) 0;

      .indicator {
        height: 14px;
        width: 14px;
        border-radius: 90px;

        &.unavailable { background-color: var(--color-neutral); }
        &.online { background-color: var(--mti-green); }
        &.offline { background-color: var(--mti-red); }
      }
    }
  }
}

@media (max-width: 1025px) {
  .store-details-comp {
    .content {
      .row {
        flex-direction: column;
        gap: var(--pad-lg);
        margin: 0;
      }

      .hub-info {
        flex-direction: row;
        gap: var(--pad-xs);
        margin: var(--pad) 0;
      }

      .col {
        width: 100%;
        margin: var(--pad-sm) 0;
      }

      .second-row { margin-top: var(--pad-lg); }
    }
  }
}
