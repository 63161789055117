.searchable-multiselect-comp {
  max-width: 500px;

  ul {
    font-size: var(--font-size-small);
    list-style: none;
    border: none;

    &.open {
      border: 1px solid var(--color-neutral-light);
      border-radius: var(--corner-radius);
      max-height: 200px;
      overflow: scroll;
    }

    li {
      padding: var(--pad-sm);
      cursor: pointer;
    }
  }

  .selected-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--pad-xs);
    margin-bottom: var(--pad-sm);

    .selected-location {
      transform: translateY(1px);
      font-size: var(--font-size-small);
      background-color: var(--color-neutral-light);
      border-radius: var(--corner-radius);
      padding: var(--pad-xs) var(--pad-sm);
      gap: var(--pad-xs);

      i {
        color: var(--color-neutral-dark);

        &:hover {
          cursor: pointer;
          color: var(--color-on-neutral);
        }
      }
    }
  }
}
