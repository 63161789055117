.users-comp {
  .user-cell-comp {
    display: flex;
    align-items: center;
    cursor: pointer;

    .info-container { flex: 1; }

    i { font-size: var(--action-icon-size); }
  }

  .store-select-comp {
    background: inherit;
    border: none;
    outline: none;
  }
}

.users-filter-comp {
  gap: var(--pad-sm);
}
