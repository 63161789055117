#authenticated-layout-comp {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  #main-container {
    flex: 1;
    position: relative;
    overflow: hidden;

    #page-container {
      height: 100%;
      overflow: scroll;
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 961px) {
    #main-container {
      display: flex;
      align-items: stretch;

      #page-container {
        flex: 1;
      }
    }
  }
}
