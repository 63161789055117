.position-cell-comp {
  display: flex;
  align-items: center;

  .state-icon {
    position: relative;
    flex-shrink: 0;
    color: var(--color-neutral);
    margin-right: var(--pad);
    display: flex;
    i { font-size: 28pt; }

    .health-icon {
      position: absolute;
      bottom: -9pt;
      right: -3pt;
      background: white;
      border-radius: 100%;
      i { font-size: 16pt; }
    }
  }

  .info { flex: 1; }

  .ports-container {
    gap: var(--pad-sm);
    margin-top: var(--pad-xs);

    .indicators-container {
      gap: var(--pad-sm);
      margin-top: 1px;

      .indicator {
        height: 10px;
        width: 10px;
        border-radius: 90px;

        &.unused { background-color: var(--color-neutral); }
        &.unhealthy { background-color: var(--mti-yellow); }
        &.healthy { background-color: var(--mti-green); }
      }
    }
  }
}
