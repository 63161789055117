#sidebar-comp {
  --sidebar-width: 180pt;

  #layout-overlay {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.66;
    transition: opacity 0.25s;
  }

  #sidebar-drawer {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--sidebar-width);
    transition: width 0.25s;
    box-shadow: var(--shadow-1);

    #sidebar-panel {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: var(--sidebar-width);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: var(--color-surface);
      color: var(--color-on-surface);

      & > .menu-item-comp {
        border-bottom: none;

        &:first-of-type {
          border-top: solid 1px var(--color-neutral-light);
        }
      }
    }
  }

  &.closed {
    #sidebar-drawer { width: 0pt; }

    #layout-overlay {
      pointer-events: none;
      opacity: 0;
    }
  }

  @media (min-width: 961px) {
    width: var(--sidebar-width);
    &.closed #sidebar-drawer { width: var(--sidebar-width); }
    #layout-overlay { display: none; }
  }
}
