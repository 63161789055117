.stores-comp {
  .store-cell-comp {
    justify-content: space-between;

    @media (min-width: 961px) {
      flex-direction: row;
      align-items: center;
    }

    .location-info, .branch-code {
      @media (min-width: 961px) {
        text-align: right;
        justify-content: right;
      }
    }

    .location-info { gap: var(--pad); }
  }
}

.stores-filter-comp {
  gap: var(--pad-sm);
}
