.date-field-comp {
  overflow: scroll;
  display: flex;
  align-items: stretch;
  flex-shrink: 0;
  flex-direction: column;

  span { font-size: smaller; }

  input {
    font-family: var(--font-field);
    font-size: inherit;
    border: none;
    outline: none;
    background-color: inherit;
  }
}
