#store-layout-comp {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  #tabbar-comp {
    z-index: 2;
    background-color: var(--color-primary-dark);
    color: var(--color-on-primary-dark);
    display: flex;
    align-items: stretch;
    justify-content: center;

    button {
      min-height: 0;
    }

    #breadcrumb-container {
      display: none;

      @media (min-width: 961px) {
        flex: 1;
        display: flex;
        align-items: stretch;
        overflow: scroll;
      }
    }

    #action-container {
      flex-shrink: 0;
      display: flex;
    }

    a {
      padding: 0pt;
      display: flex;
      align-items: stretch;

      #tab-link-comp {
        position: relative;
        padding: var(--pad-sm); /* 8pt 10pt; */
        display: flex;
        align-items: center;

        #highlight {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: var(--color-accent);
          height: 4pt;
          opacity: 0%;
          border-radius: var(--corner-radius);
        }
      }

      &.selected #tab-link-comp {
        #highlight { opacity: 100%; }
      }
    }
  }
}
