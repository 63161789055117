.reports-comp {
  .filters-container {
    border-bottom: solid 1px var(--color-neutral);
    overflow: scroll;
    padding: var(--pad-xs);

    .filters {
      display: none;
      gap: var(--pad);
    }

    #btn-open-filter {
      margin-left: auto;
      display: inherit;
    }

    @media (min-width: 961px) {
      .filters { display: flex; }
      #btn-open-filter { display: none; }
    }
  }

  .col:first-child { flex: 3; }
  .col:last-child { flex: 1; }

  .page-load-message {
    padding: var(--pad);
    text-align: center;
  }
}

.reports-filter-comp {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--pad);
}
