.edit-password-comp {
  @media (min-width: 961px) {
    max-width: 600pt;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: var(--pad);
  }

  .header {
    display: flex;
    align-items: center;
    padding-right: var(--pad-lg);
  }
}
