.device-list-comp {
  overflow: scroll;

  select {
    font-size: var(--font-size-small);
  }
}

.device-filters-comp {
  gap: var(--pad-xs);

  .action-container {
    gap: var(--pad-sm);

    button { width: 50%; }
  }
}
