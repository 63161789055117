.loading-spinner-comp {
  --ls-color: var(--loading-spinner-color, var(--color-primary-light));
  --ls-size: var(--loading-spinner-size, 50pt);

  display: flex;
  justify-content: center;
  align-items: center;

  &.large { --ls-size: 50pt; }
  &.small { --ls-size: 17pt; }

  .loading-spinner {
    display: inline-block;
    position: relative;
    min-width: var(--ls-size);
    min-height: var(--ls-size);

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0%;
      border: 3px solid var(--ls-color);
      border-radius: 50%;
      border-color: var(--ls-color) transparent transparent transparent;
      animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      opacity: 1;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.hidden .loading-spinner { opacity: 0; }
}
