.reset-password-comp {
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .card {
    padding: var(--pad-lg);

    @media (min-width: 961px) {
      max-width: 50%;
    }

    &.col, .col {
      gap: var(--pad-lg);
    }
  }
}
