.position-modal-comp {
  min-width: 200pt;
  gap: var(--pad-sm);

  .battery-device-container { justify-content: space-between; }
  .row { gap: var(--pad-xs); }
  .alarming, .critical { color: var(--mti-red); }
  .unhealthy, .low { color: var(--mti-yellow); }
  .high { color: var(--mti-green); }
  .ports-container { gap: var(--pad-sm); }

  .position-name {
    display: flex;
    align-items: center;
    gap: var(--pad-xs);
    @media (max-width: 640px) { font-size: 18pt; }
  }
}

.device-assignment-comp {
  gap: var(--pad-xs);

  .list {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 300pt;
    border: 1px solid var(--color-neutral);
    border-radius: var(--corner-radius);
    margin: var(--pad-sm) 0;

    .cell {
      &:last-of-type { border-bottom: none; }
    }
  }

  .selected {
    background: var(--color-primary-light);
    color: var(--color-on-primary-light);
  }

  .serial {
    font-size: 10pt;
    @media (min-width: 961px) { font-size: inherit; }
  }
}

.port-cell-comp {
  .col { gap: var(--pad-xs); }

  .port-address > :first-child { flex: 1; }

  .indicator {
    height: 14px;
    width: 14px;
    border-radius: 90px;

    &.unused { background-color: var(--color-neutral); }
    &.unhealthy { background-color: var(--mti-yellow); }
    &.healthy { background-color: var(--mti-green); }
  }
}
