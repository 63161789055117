.page-header-comp {
  background-color: #efefef;
  border-bottom: solid 1px #dadada;
  display: flex;
  align-items: center;

  .title {
    flex: 1;
    padding: var(--pad-sm) var(--pad);
  }

  .actions {
    display: flex;
    align-items: center;
  }
}
