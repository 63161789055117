.text-field-comp {
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  input {
    font-family: var(--font-field);
    font-size: inherit;
    /* margin: var(--pad-xs) var(--pad-sm); */
    margin: 3pt var(--pad) 6pt var(--pad);
    border: none;
    outline: none;
    background-color: inherit;
  }

  #platform {
    height: 1px;
    background-color: var(--color-neutral);
    transition: background-color 0.25s;
    width: 100%;

    &.focused {
      background-color: var(--color-primary-light);
    }
  }
}
