.app-layout-comp {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .app-container {
    flex: 1;
    position: relative;
  }
}
