.key-cell-comp {
  display: flex;
  align-items: center;
  gap: var(--pad);

  .info { flex: 1; }

  i { font-size: var(--action-icon-size); }

  #activate-btn i {
    color: var(--color-neutral-dark);
  }

  &.inactive {
    .title { color: var(--color-neutral-dark); }
  }

  &.active {
    #activate-btn i { color: var(--mti-green); }
  }
}

.edit-key-comp {
  form {
    gap: var(--pad-sm);

    h1 { font-size: var(--font-size-large); }

    .backup-key-container { gap: var(--pad-sm); }
  }
}

.key-filters-comp {
  gap: var(--pad-sm);
}
