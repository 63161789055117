.iot-dashboard-comp {
  background: var(--color-surface);
  overflow: scroll;
}

.iot-dashboard-cell-comp {
  justify-content: space-between;

  .detail { display: none; }
  &.show-details { .detail { display: inherit; } }

  .store-name {
    font-size: var(--font-size-large);
    padding: 0 0 var(--pad) 0;
  }

  .devices-container {
    font-size: var(--font-size-small);
    gap: var(--pad);

    .row {
      align-items: center;
      justify-content: space-between;

      .col {
        gap: var(--pad-xs);
      }
    }

    .timestamp {
      text-transform: uppercase;
      font-size: 8pt;
      letter-spacing: 1px;
    }
  }

  /* The 'show-details' mode will always display in a column, but the more simplified view will
   display in a row (on desktop) or a column (on mobile). */
  &:not(.show-details) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: scroll;

    @media (min-width: 1281px) {
      flex-direction: row;
      align-items: center;
      gap: var(--pad-sm);

      .store-name {
        font-size: inherit;
        padding: inherit;
      }
    }

    .devices-container {
      @media (min-width: 1281px) {
        flex-direction: row;
        align-items: center;
        gap: var(--pad-xl);

        .row {
          @media (min-width: 1281px) {
            gap: var(--pad-sm);
          }
        }
      }
    }
  }

  .online, .offline {
    font-size: 9pt;
    letter-spacing: 1pt;
    text-transform: uppercase;
    gap: var(--pad-xs);
    align-items: center;
    justify-content: center;
    padding: var(--pad-xs) var(--pad-sm);
    border-radius: var(--corner-radius);
    background-color: var(--color-neutral-light);
    min-width: 80px;

    .icon {
      width: 14px;
      height: 14px;
      border-radius: 50%;

      &.on {
        background-color: var(--color-success);
      }

      &.off {
        background-color: var(--color-error);
      }
    }
  }
}
