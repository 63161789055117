.list-view-comp {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: scroll;

  #list-container {
    overflow: scroll;
    flex: 1;
  }

  .alternate {
    .cell { border-bottom: none; }

    .cell:nth-child(2n+1) {
      background-color: #f3f3f3;
    }
  }

  .pagination-container {
    display: flex;
    background: #efefef;
    border-top: solid 1px #dadada;

    .pagination-controls {
      margin: auto;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }
}
