#top-nav-comp {
  height: var(--bar-height);
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: var(--shadow-1);
  z-index: 3;
  flex-shrink: 0;

  #logo {
    height: 25pt;
    width: same-as-height;
    display: none;
  }

  #brand-title {
    text-transform: uppercase;
    margin-left: var(--pad);
    display: none;
  }

  #store-name {
    padding: var(--pad-sm);
    cursor: pointer;
    flex-grow: 1;
  }

  .logger-icon {
    padding: 0 var(--pad-sm);
  }

  @media (min-width: 961px) {
    #mobile-menu-btn { display: none; }
    #store-name { display: none; }
    #brand-title { display: block; }

    #logo {
      display: block;
      margin-left: var(--pad);
    }
  }
}
